import { SectionHeading } from 'components/atomic/atoms';
import { VideoCardListMobile } from 'components/atomic/organism';

const EventGlimpsesMobile = ({ eventGlimpsesList = [] }) => {
  return (
    <div className='bg-white pb-8 pr-3'>
      <SectionHeading
        letterSpacing='3px'
        title='A Glimpse into our Events'
        underline
      />
      <VideoCardListMobile
        {...{
          cardList: eventGlimpsesList
        }}
      />
    </div>
  );
};

export default EventGlimpsesMobile;
