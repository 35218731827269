import dynamic from 'next/dynamic';
import { Fragment, useState } from 'react';

import MobileNavbar from 'components/Common/MobileNavbar';
import MobileHomeHeader from 'components/Common/header/mobile/MobileHomeHeader';
import {
  BecomePartner,
  TopBrandsMobile,
  TrendingServices,
  TrustedPartners
} from 'components/atomic/molecules';
import { TrendingCollection } from 'components/atomic/organism';
import AboutSectionMobile from 'components/homePage/mobile/AboutSectionMobile';
import EventGlimpsesMobile from 'components/homePage/mobile/EventGlimpsesMobile';
import FeaturedCategories from 'components/homePage/mobile/FeaturedCategories';
import HaflaBlogs from 'components/homePage/mobile/HaflaBlogs';
import WhyChooseHaflaMobile from 'components/homePage/mobile/WhyChooseHaflaMobile';
import Layout from 'components/layout/DefaultLayout';
import eventGlimpsesList from 'data/event-glimpses';
import haflaServices from 'data/why-choose-hafla';

const HowDoesItWork = dynamic(
  () => import('components/homePage/HowDoesItWork'),
  {
    ssr: false
  }
);

const tempInactive = false;
const MobileHome = ({ bannerTranslation, data }) => {
  const [showSearchModal, setShowSearchModal] = useState(false);

  return (
    <Fragment>
      <MobileHomeHeader
        isHomePage={true}
        showSearchModal={showSearchModal}
      />
      <Layout
        paramData={{
          bannerTranslate: bannerTranslation,
          translate: bannerTranslation,
          eventCategory: 'home',
          homeScreen: true
        }}
      >
        <TopBrandsMobile />
        <TrendingServices />
        <FeaturedCategories />
        <TrustedPartners />

        <EventGlimpsesMobile eventGlimpsesList={eventGlimpsesList} />
        <BecomePartner />
        <TrendingCollection {...{ collectionList: data?.collections }} />
        <WhyChooseHaflaMobile haflaServices={haflaServices} />
        <HaflaBlogs posts={data?.blogPost} />
        {tempInactive && <HowDoesItWork {...{ isMobile: true }} />}
        <MobileNavbar
          onPage={'home'}
          showSearchModal={showSearchModal}
          setShowSearchModal={setShowSearchModal}
        />
        <AboutSectionMobile />
      </Layout>
    </Fragment>
  );
};

export default MobileHome;
