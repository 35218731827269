import Image from 'next/image';
import Link from 'next/link';

import { CardMobile } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const RoundButtonWithText = ({ link, imageSrc, bottomText }) => (
  <div className='ps-4 pe-12 block'>
    <Link href={link}>
      <div className='text-center max-w-18 mx-auto'>
        <div className='bg-more-blog h-17 w-17 rounded-full flex justify-center items-center cursor-pointer shadow-search'>
          <div className='relative h-4'>
            <Image
              alt={bottomText}
              height={15}
              src={imageSrc}
              width={21}
            />
          </div>
        </div>
        <span className='text-nero font-medium font-Inter text-xs leading-4 mt-3'>
          {bottomText}
        </span>
      </div>
    </Link>
  </div>
);

const CardListMobile = ({
  cardList = [],
  imageBasePath,
  imageSlugPrefix,
  isViewButtonVisible,
  viewButtonLink
}) => (
  <div className='ps-2.5 xxxs:ps-5 mt-6'>
    <div className='flex space-x-4 flex-nowrap no-scrollbar items-center card-scroll'>
      {cardList.map(
        ({
          bannerImageUrl,
          id,
          link,
          name,
          slug,
          title,
          yoast_head_json: yoastHeadJson
        }) => {
          let slugParsed = '';
          if (link) {
            slugParsed = link;
          } else if (slug) {
            slugParsed = `${imageSlugPrefix}${slug}`;
          }
          return (
            <CardMobile
              key={id}
              {...{
                bannerImageUrl:
                  yoastHeadJson?.og_image[0]?.url || bannerImageUrl,
                imageBasePath,
                slug: slugParsed,
                title: title?.rendered || name,
                type: 'blog'
              }}
            />
          );
        }
      )}

      {isViewButtonVisible && (
        <RoundButtonWithText
          {...{
            bottomText: 'View All',
            imageSrc: `${staticMediaStoreBaseURL}/icons/right-white.svg`,
            link: viewButtonLink
          }}
        />
      )}
    </div>
  </div>
);

export default CardListMobile;
