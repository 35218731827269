import Image from 'next/image';
import Link from 'next/link';

import { SectionHeading, Text } from 'components/atomic/atoms';
import { staticMediaStoreBaseURL } from 'config/common';
import featuredCategories from 'data/featured-categories';

const FeaturedCategories = () => (
  <div className='py-10 px-4'>
    <SectionHeading
      {...{
        letterSpacing: '3px',
        title: ' Explore Services',
        underline: true
      }}
    />
    <div className='grid grid-cols-2 gap-4 pt-8'>
      {featuredCategories.map(({ href, logoUrl, name }, id) => (
        <Link
          href={href}
          key={id}
        >
          <div className='flex flex-col items-center overflow-hidden gap-2'>
            <div className='w-full h-full relative shadow'>
              <Image
                alt={name}
                className='rounded-lg'
                height={110}
                layout='responsive'
                objectFit='cover'
                src={`${staticMediaStoreBaseURL}${logoUrl}`}
                width={100}
              />
            </div>
            <div className='w-full'>
              <Text
                {...{
                  className: 'font-Montserrat font-semibold',
                  content: name
                }}
              />
            </div>
          </div>
        </Link>
      ))}
    </div>
  </div>
);

export default FeaturedCategories;
