import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const TopBrandsRow = ({ renderRow }) => {
  const [isMobile] = useIsMobile();
  return (
    <tr className='wave-border'>
      <td />
      {renderRow.map(({ alt, logoURL }, index) => (
        <td
          key={`${alt}-${index}`}
          className='relative md:p-4 group'
        >
          <div>
            <Image
              alt='logo'
              height={isMobile ? 30 : 48}
              layout='fixed'
              priority={true}
              quality={100}
              src={`${staticMediaStoreBaseURL}/home-page/top-brands/${logoURL}`}
              width={isMobile ? 60 : 96}
            />
          </div>
        </td>
      ))}
      <td />
    </tr>
  );
};

export default TopBrandsRow;
