const tailwindcssRtl = require('tailwindcss-rtl');

const STATIC_MEDIA_STORE_PATH_STUB = '/static_images/host';
const mediaStoreBaseURL =
  process.env.STORYBOOK_PUBLIC_MEDIA_STORE_URL ||
  process.env.NEXT_PUBLIC_MEDIA_STORE_URL ||
  '';
const staticMediaStoreBaseURL = `${mediaStoreBaseURL}${STATIC_MEDIA_STORE_PATH_STUB}`;

const colors = {
  brand: '#EA4842',
  white: '#FFFFFF',
  orange: '#FF794D',
  platinum: '#E4E4E4',
  cinnabar: '#EE3D24',
  emerald: '#4CD964',
  'error-red': '#FF3200',
  nero: '#222222',
  gray: '#EDEDED',
  lightpink: '#FDF1EE',
  coral: '#FF794D',
  shamrock: '#40DCA8',
  squeaky: '#6FC2DD',
  snowwhite: '#FBFBFB',
  'dim-gray': '#717171',
  'ghost-white': '#F6F6F7',
  'light-green': '#E0FFE5',
  blue: '#0000BB',
  green: '#009518',
  silver: '#BABABA',
  'light-purple': '#6461E1',
  'tickle-pink': '#ff80a6',
  'light-coral': '#EE7D93',
  clearblue: '#247AFD',
  'light-red': '#FFF1EC',
  lightgray: '#FCFCFC',
  lightblue: '#556EE6',
  greenlight: '#EAF3EB',
  'green-base': '#29823B',
  neutral: '#F3F3F6',
  'orange-dark': '#f75011',
  'white-base': '#ffefe9',
  'warning-light': '#FDF4E5',
  'warning-dark': '#E99400',
  'planner-cart-head': '#F9F9FB',
  highlight: '#FFD',
  'unavailable-error': '#FFF6FB',
  'error-base': '#DC2020',
  'error-base-bg': '#FCEAEA',
  'basic-black': '#0C0C1E',
  pumpkins: '#F38023',
  'error-tool-tip': '#FBEFF2',
  'olive-black': '#3B3B3B',
  'info-base': '#017AAD',
  'info-light': '#E5F5FC',
  'light-silver': '#9A9A9A',
  'rich-black': '#020202'
};

module.exports = {
  purge: [
    './pages/*.js',
    './pages/**/*.js',
    './pages/**/**/*.js',
    './pages/**/**/**/*.js',
    './pages/**/**/**/**/*.js',
    './atoms/*.js',
    './atoms/**/*.js',
    './molecules/*.js',
    './molecules/**/*.js',
    './molecules/**/**/*.js',
    './molecules/**/**/**/*.js',
    './components/*.js',
    './components/**/*.js',
    './components/**/**/*.js',
    './components/**/**/**/*.js',
    './components/**/**/**/**/*.js',
    './organisms/*.js',
    './organisms/**/*.js',
    './organisms/**/**/*.js',
    './organisms/**/**/**/*.js'
  ],
  options: {
    whitelist: ['.grid', '.grid-cols-3', '.gap-x-5', 'xl']
  },
  theme: {
    letterSpacing: {
      'most-wider': '5.6px'
    },
    screens: {
      fold: '280px',
      basic: '320px',
      xxxs: '360px',
      xxs: '380px',
      xs: '520px',
      sm: '640px',
      md: '768px',
      tab: '992px',
      lg: '1024px',
      'lg-l': '1140px',
      xl: '1280px',
      'xl-1': '1380px',
      '2xl': '1536px',
      '3xl': '1700px'
    },

    extend: {
      gridTemplateRows: {
        // Simple 8 row grid
        8: 'repeat(8, minmax(0, 1fr))'
      },
      maxWidth: () => ({
        '10/12': 'calc(1000%/12)',
        '1/4': '25%',
        '2/4': '50%',
        msearch: 'calc(100% - 25px)',
        '57%': '57%',
        '43%': '43%',
        '85/100': '85%',
        20: '5rem',
        34: '8.5rem',
        63: '15.75rem',
        17.5: '4.375rem',
        105: '26.25rem',
        152.5: '38.125rem',
        155: '38.75rem',
        260: '65rem',
        165: '41.25rem',
        48: '12rem',
        40: '10rem',
        26: '6.5rem',
        30: '7.5rem',
        180: '45rem',
        68: '17rem',
        64: '16rem',
        60: '15rem',
        7: '1.75rem',
        94: '23.5rem',
        271: '67.75rem',
        128: '32rem',
        100: '25rem',
        75: '18.75rem',
        2.5: '0.625rem',
        1.5: '0.375rem',
        143.5: '35.875rem',
        265: '66.25rem',
        52: '13rem',
        76: '19rem',
        3.5: '0.875rem',
        70: '17.50rem',
        55: '13.75rem',
        120: '30rem',
        156: '39rem',
        182.5: '45.625rem',
        25: '6.125rem',
        15: '3.75rem',
        152: '38rem',
        85: '21.25rem',
        53: '13.25rem',
        16: '4rem',
        14: '3.5rem',
        108: '27rem',
        194: '48.5rem',
        44: '11rem',
        42: '10.5rem',
        116.5: '29.125rem',
        50: '12.5rem',
        47.5: '11.875rem',
        28: '7rem',
        35: '8.75rem',
        36: '9rem',
        38: '9.5rem',
        80: '20rem',
        62.5: '15.625rem',
        71.5: '17.875rem',
        136: '34rem',
        96.5: '24.25rem',
        18: '4.5rem',
        122: '30.5rem',
        37: '9.25rem',
        111: '27.75rem',
        72.5: '18.125rem',
        47: '11.75rem',
        57: '14.125rem',
        21.5: '5.375rem',
        24.5: '6.125rem',
        158: '39.5rem',
        6: '1.5rem',
        115: '28.75rem',
        27.5: '6.875rem',
        58.5: '14.625rem',
        37.5: '9.375rem',
        160: '40rem',
        66.75: '66.75rem'
      }),

      minHeight: () => ({
        96: '24rem',
        80: '20rem',
        43.5: '10.875rem',
        40: '10rem',
        150: '37.5rem',
        52: '13rem',
        107.5: '26.875rem',
        105: '26.25rem',
        86: '21.5rem',
        leadFormHeight: 'calc(100vh - 56px)',
        35: '8.75rem',
        64: '16rem',
        33: '8.25rem',
        45: '11.25rem',
        448: '28rem'
      }),

      maxHeight: () => ({
        55: '13.75rem',
        7.5: '1.875rem',
        21: '5.25rem',
        20.5: '5.625rem',
        150: '37.5rem',
        27.5: '21.875rem',
        50: '12.5rem',
        3.5: '0.875rem',
        content: 'max-content',
        12.5: '3.125rem',
        23: '5.75rem',
        75: '18.75rem',
        35: '8.75rem',
        114: '28.5rem',
        18: '4.5rem',
        105: '26.25rem',
        128: '32rem',
        81.5: '20.375rem',
        33: '8.25rem',
        47: '11.75rem',
        37.5: '9.375rem',
        45: '11.25rem'
      }),

      height: () => ({
        200: '50rem',
        528: '33.625rem',
        240: '60rem',
        'screen/1.3': '75vh',
        'screen/2': '50vh',
        58: '3.625rem',
        13: '3.25rem',
        4.5: '1.125rem',
        25: '6.25rem',
        148: '37rem',
        140: '35rem',
        37: '9.25rem',
        87.5: '21.875rem',
        112: '28rem',
        18: '4.5rem',
        104: '26rem',
        120: '30rem',
        17: '4.25rem',
        100: '25rem',
        101: '25.25rem',
        128: '32rem',
        43: '10.75rem',
        7.5: '1.875rem',
        15: '3.75rem',
        20.5: '5.125rem',
        21.5: '5.375rem',
        21: '5.25rem',
        22: '5.5rem',
        23: '5.75rem',
        22.5: '5.625rem',
        5.5: '1.375rem',
        71.5: '17.875rem',
        150: '37.5rem',
        8.3: '2.063rem',
        57: '14.25rem',
        152: '38rem',
        154: '38.75rem',
        30: '7.5rem',
        30.5: '7.625rem',
        18.5: '4.625rem',
        74: '18.5rem',
        12.5: '3.125rem',
        15.5: '3.875rem',
        10.5: '2.625rem',
        50: '12.5rem',
        105: '26.25rem',
        47: '11.75rem',
        126: '31.25rem',
        80: '20rem',
        66: '16.5rem',
        31.5: '7.875rem',
        46: '11.50rem',
        '90vh': '90vh',
        customscreen: 'Calc(100vh - 2.5rem)',
        61: '15.25rem',
        45: '11.25rem',
        6.5: '1.625rem',
        'screen5/6': '83.33vh'
      }),

      minWidth: () => ({
        14: '3.5rem',
        4: '1rem',
        70: '17.5rem',
        15: '3.75rem',
        34: '8.5rem',
        63: '15.75rem',
        65: '16.25rem',
        41: '10.25rem',
        33: '8.25rem',
        30: '7.5rem',
        44: '11rem',
        26.5: '6.625rem',
        36: '9rem',
        auto: 'auto',
        '7/10': '70%',
        45: '11.25rem',
        32.5: '8.125rem',
        57: '14.125rem',
        47: '12.75rem',
        6: '1.5rem',
        38: '9.5rem',
        8: '2rem',
        22: '5.5rem'
      }),

      width: () => ({
        '6.5/10': '65%',
        '9/10': '90%',
        '9/20': '45%',
        '78/100': '78%',
        '11/20': '55%',
        '30/100': '30%',
        '23/100': '23%',
        '22/25': '88%',
        '7/10': '70%',
        4.5: '1.125rem',
        42.5: '10.625rem',
        26: '6.25rem',
        82: '20.5rem',
        88: '22rem',
        152: '38rem',
        15: '3.75rem',
        22.5: '5.625rem',
        22: '5.5rem',
        25: '6.5rem',
        112: '28rem',
        108: '31rem',
        128: '32rem',
        17.5: '4.375rem',
        17: '4.25rem',
        7.5: '1.875rem',
        100: '25rem',
        150: '37.5rem',
        73: '18.25rem',
        68: '17rem',
        45: '11.25rem',
        13: '3.25rem',
        5.5: '1.375rem',
        95: '23.75rem',
        32.5: '8.125rem',
        8.3: '2.063rem',
        30: '7.5rem',
        30.5: '7.625rem',
        20.5: '5.125rem',
        43: '10.75rem',
        18: '4.5rem',
        18.5: '4.625rem',
        19.5: '4.875rem',
        11.5: '2.875rem',
        126: '31.25rem',
        47: '11.75rem',
        37: '9.25rem',
        6.5: '1.625rem',
        41: '41.875rem',
        66.75: '66.75rem',
        39.375: '39.375rem',
        46.563: '46.563rem',
        47.5: '47.5rem',
        56.25: '56.25rem',
        16.6: '16.5rem'
      }),

      margin: () => ({
        5: '1.25rem',
        18: '4.5rem',
        17: '4.25rem',
        19.5: '4.875rem',
        19: '4.75rem',
        53: '13.25rem',
        13: '3.25rem',
        4.5: '1.125rem',
        25: '6.25rem',
        '5px': '0.313rem',
        '-45': '-11.25rem',
        15: '3.75rem',
        5.5: '1.375rem',
        7.5: '1.875rem',
        14: '3.5rem',
        '15px': '0.938rem',
        22: '5.5rem',
        29.5: '7.375rem'
      }),

      padding: () => ({
        0.5: '2px',
        13: '3.25rem',
        18: '4.5rem',
        53: '13.25rem',
        5.5: '1.375rem',
        7.5: '1.875rem',
        4.5: '1.125rem',
        17: '4.25rem',
        88.5: '22.125rem',
        '5px': '0.313rem',
        '3px': '0.188rem',
        '11px': '0.688rem',
        15: '3.75rem',
        17.5: '4.375rem',
        39: '9.75rem',
        13.5: '3.375rem',
        22: '5.625rem',
        6.5: '1.625rem',
        15.5: '3.875rem',
        12.5: '3.125rem',
        10.5: '2.625rem',
        '16/9': '56.25%',
        34: '8.5rem'
      }),

      borderRadius: () => ({
        '7px': '0.438rem',
        '5px': '0.313rem',
        2.5: '0.625rem',
        5: '1.125rem',
        6.5: '1.625rem',
        7.5: '1.875rem',
        4: '1rem',
        '4xl': '2rem',
        1.5: '0.375rem'
      }),

      divideColor: () => ({
        gray: '#EDEDED'
      }),

      borderColor: () => ({
        ...colors
      }),

      backgroundColor: () => ({
        ...colors
      }),

      textColor: () => ({
        ...colors
      }),

      fontWeight: () => ({
        normal: '400',
        'above-normal': '450'
      }),

      fontSize: () => ({
        xxs: '0.5rem',
        '28px': '1.75rem',
        '33px': '2.063rem',
        '35px': '2.188rem',
        '27px': '1.688rem',
        '11px': '0.688rem',
        '10px': '0.625rem',
        '26px': '1.625rem',
        '17px': '1.063rem',
        '45px': '2.813rem',
        '32px': '2rem',
        '40px': '2.5rem',
        56: '56px',
        '58px': '3.625rem',
        '22px': '1.375rem',
        '15px': '0.938rem',
        4.5: '1.125rem',
        2.5: '0.625rem',
        6.255: '1.564rem'
      }),

      fontFamily: () => ({
        Montserrat: 'Montserrat',
        brandon: 'brandon_textbold',
        Inter: 'Inter',
        'google-basic': 'google-basic'
      }),

      lineHeight: () => ({
        none: '0rem',
        12: '3rem',
        16: '4rem',
        12.5: '3.5rem',
        '21px': '1.313rem',
        25: '1.5rem',
        '41px': '2.563rem',
        6.5: '1.625rem',
        '17px': '1.063rem',
        1: '0.25rem',
        2: '0.50rem',
        2.5: '0.625rem',
        '11px': '0.688rem',
        53: '53px',
        13.5: '3.375rem',
        '15px': '0.938rem',
        3.5: '0.875rem',
        4.5: '1.125rem',
        7.5: '1.875rem',
        14.5: '3.625rem',
        5.984: '1.496rem',
        '34px': '2.125rem'
      }),

      inset: () => ({
        '11/50': '22%',
        '2.5%': '2.5%',
        '2/50': '4%',
        '3/10': '30%',
        '1/6': '18%',
        '1/5': '20%',
        '1/10': '10%',
        '1/20': '4.3%',
        '81.2/100': '81.2%',
        '34/100': '34%',
        '46/100': '46%',
        '43/100': '43%',
        '38/100': '38%',
        '30/100': '30%',
        '33.7%': '33.7%',
        '18/100': '18%',
        12.5: '3.5rem',
        '19px': '1.188rem',
        '13px': '0.813rem',
        42: '10.5rem',
        32.5: '8.125rem',
        33: '32.5rem',
        33.5: '8.375rem',
        46: '11.5rem',
        '-5px': '-0.313rem',
        '87px': '5.438rem',
        '-3px': '-0.188rem',
        '-38': '-9.5rem',
        21.5: '5.375rem',
        126: '7.875rem',
        4.5: '1.125rem',
        7.5: '120px',
        74: '18.5rem',
        18: '4.5rem',
        19: '4.75rem',
        35.5: '8.875rem',
        22.5: '5.625rem',
        11.5: '2.875rem',
        '80%': '80%'
      }),

      backgroundImage: () => ({
        'payment-modal': `url('${staticMediaStoreBaseURL}/home-page-banner/hafla-banner-image-web.jpg')`,
        'payment-modal-mobile': `url('${staticMediaStoreBaseURL}/home-page-banner/hafla-banner-image-mobile.jpg')`,
        'event-plan': `url('${staticMediaStoreBaseURL}/event-plan/purple-event-plan-card.jpg')`,
        'about-hafla-footer': "url('/svg/about-hafla-footer.svg')",
        'mobile-event-plan': `url('${staticMediaStoreBaseURL}/event-plan/mobile-event-plan.jpg')`,
        'brand-gradient':
          'linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%)',
        'schedule-btn':
          'linear-gradient(72.65deg, #F684C8 -11091.14%, #FAC174 29050.1%)',
        'leadform-gradient':
          'linear-gradient(63.67deg, #F684C8 8.28%, #FAC174 121.11%)',
        'b2b-btn': 'linear-gradient(90deg, #F684C8 -0.01%, #FAC174 100%)',
        'b2b-hover': 'linear-gradient(90deg, #FAC174 -0.01%, #F684C8 100%)',
        'lets-go-btn':
          'linear-gradient(63.67deg, #F684C8 8.28%, #FAC174 121.11%)',
        banner: `url('${staticMediaStoreBaseURL}/home-page-banner/mobile-banner.jpg')`,
        list: 'linear-gradient(53.4deg, #E58023 -9.15%, #CF578F 77.95%, #E03D24 155.2%)',
        'pink-gradient':
          'linear-gradient(63.67deg, #F684C8 8.28%, #FAC174 121.11%)',
        'green-gradient':
          'linear-gradient(48.54deg, #008DDC 17.07%, #32FF47 107.01%)',
        'purple-gradient':
          'linear-gradient(44.84deg, #9796F0 59.65%, #FBC7D4 107.01%)',
        'red-gradient': 'linear-gradient(90deg, #F2709C 0%, #FF9472 100%)',
        control:
          'linear-gradient(86.7deg, #E58023 -30.01%, #CF578F 95.46%, #E03D24 206.73%)',
        'start-btn':
          'linear-gradient(87.91deg, #F38023 -22.26%, #DD579D 105.95%, #EE3D24 219.5%)',
        'more-blog':
          'linear-gradient(47.15deg, #E88023 -15.73%, #D25792 66.71%, #E33D24 139.81%)',
        cart: 'linear-gradient(66.63deg, #F38023 -42.75%, #DD579D 91.47%, #EE3D24 210.35%)',
        border:
          'linear-gradient(66.43deg, #E58023 -33.51%, #CF578F 94.65%, #E03D24 207.46%);',
        'light-brand':
          'linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%);',
        'light-red':
          'linear-gradient(66.43deg, rgba(229, 128, 35, 0.15) -33.51%, rgba(207, 87, 143, 0.15) 94.65%, rgba(224, 61, 36, 0.15) 207.46%);',
        'planner-cart-bg': `linear-gradient(66.43deg, #E58023 -33.51%, #CF578F 94.65%, #E03D24 207.46%),linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%)`,
        'become-partner-background': `url('${staticMediaStoreBaseURL}/home-page/become-partner-background.svg')`,
        'hero-banner-bottom-background': `url('${staticMediaStoreBaseURL}/home-page-banner/hero-banner-background-mobile.svg')`,
        'divider-gradient-background':
          'linear-gradient(90deg, #DE8000 -15%, #C85384 71.74%, #D93B00 154%)'
      }),

      gap: () => ({
        13: '3.25rem',
        4.5: '1.125rem',
        25: '6.25rem',
        5.5: '1.375rem'
      }),

      placeholderColor: () => ({
        notes: '#717171',
        default: '#868686',
        brand: '#EA4842',
        nero: '#222222',
        silver: '#BABABA'
      }),

      gradientColorStops: () => ({
        red: '#EB3349',
        orange: '#F45C43',
        indigo: '#4B0082',
        pumpkins: '#F38023',
        hopbush: '#DD579D',
        cinnabar: '#EE3D24',
        'light-orange': '#F38023 -90.01%',
        'dark-orange': '#DD579D 205.57%',
        rose: '#EE3D24 467.37%'
      }),

      boxShadow: () => ({
        orderbox: '0px 6px 19px rgba(164, 171, 190, 0.26)',
        list: '0px 3px 5px rgba(119, 119, 119, 0.28)',
        listmd: '0px 6px 19px rgba(164, 171, 190, 0.26)',
        delivery: '0px 2px 4px #D3D3D3',
        SubCategory: '0px 5px 24px rgba(0, 0, 0, 0.08)',
        profile: '0px 2px 6px #E9E9E9',
        category: '0px 2px 8px rgba(0, 0, 0, 0.12)',
        megamenu: '0px 2px 16px rgba(0, 0, 0, 0.08)',
        input: '0px 3px 8px rgba(0, 0, 0, 0.08)',
        modal: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        disclaimer: '0px 2px 4px rgba(0, 0, 0, 0.12)',
        search: '2px 2px 2px rgba(0, 0, 0, 0.1)',
        kids: '0px 2px 6px rgba(0, 0, 0, 0.12)',
        header: '0px 2px 19px rgba(164, 171, 190, 0.26)',
        event: '0px 6px 12px 0px rgba(0, 0, 0, 0.11)',
        card: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        'update-event': '0px 2px 8px 0px #00000014'
      }),

      zIndex: () => ({
        99: 99,
        101: 101,
        120: 120,
        150: 150,
        151: 151
      }),

      animation: () => ({
        'shadow-pulse': 'pulse-animation 2s infinite',
        wiggle: 'wiggle 200ms ease-in-out'
      }),

      keyframes: () => ({
        wiggle: {
          '0%, 100%': { transform: 'rotate(-3deg)' },
          '50%': { transform: 'rotate(3deg)' }
        }
      }),

      brightness: () => ({ 30: '.30' }),

      listStyleType: () => ({
        alpha: 'lower-alpha',
        roman: 'upper-roman'
      }),

      translate: () => ({
        9.5: '2.375rem',
        4.5: '1.125rem'
      })
    }
  },

  variants: {
    extend: {
      animation: ['hover', 'focus'],
      opacity: ['disabled'],
      backgroundImage: ['hover', 'focus'],
      scale: ['active', 'group-hover'],
      margin: ['hover', 'focus', 'first', 'last', 'odd', 'even'],
      padding: ['first', 'last', 'hover'],
      borderWidth: ['first', 'last', 'hover'],
      textColor: ['active', 'visited', 'group-focus'],
      visibility: ['hover', 'focus', 'group-hover', 'group-focus'],
      cursor: ['hover', 'focus'],
      fill: ['hover', 'focus'],
      borderRadius: ['hover', 'focus', 'first', 'last'],
      fontWeight: ['hover', 'focus', 'group-hover', 'group-focus'],
      display: ['group-hover'],
      rotate: ['responsive', 'hover', 'focus', 'group-hover'],
      backgroundColor: ['checked'],
      borderColor: ['checked'],
      textDecoration: ['hover', 'focus', 'group-hover', 'group-focus']
    }
  },
  plugins: [tailwindcssRtl]
};
