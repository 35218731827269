import Image from 'next/image';
import Link from 'next/link';

import { SectionHeading } from 'components/atomic/atoms';
import { staticMediaStoreBaseURL } from 'config/common';
import featuredCategories from 'data/featured-categories';

const FeaturedCategories = () => (
  <div className='py-10 px-4'>
    <SectionHeading
      letterSpacing='3px'
      title=' Explore Services'
      underline
    />
    <div className='grid grid-cols-2 gap-4 pt-8'>
      {featuredCategories.map(({ href, logoUrl, name }, id) => (
        <Link
          key={id}
          href={href}
        >
          <div className='flex flex-col items-center overflow-hidden gap-2'>
            <div className='w-full h-full relative shadow'>
              <Image
                alt={name}
                className='rounded-lg'
                height={110}
                width={100}
                layout='responsive'
                objectFit='cover'
                src={`${staticMediaStoreBaseURL}${logoUrl}`}
              />
            </div>
            <div className='w-full'>
              <div className='font-Montserrat font-semibold'>{name}</div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  </div>
);

export default FeaturedCategories;
