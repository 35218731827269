import PropTypes from 'prop-types';

const titleClassName =
  'font-google-basic text-lg md:text-35px text-nero uppercase leading-6 md:leading-34px text-center font-normal tracking-widest';
const SectionHeading = ({ letterSpacing = 3, title, subTitle, underline }) => (
  <div className='justify-items-center'>
    <div
      style={{ letterSpacing }}
      className={titleClassName}
    >
      {title}
    </div>
    {underline && (
      <div className='h-1 w-12 bg-divider-gradient-background rounded mt-2 justify-self-center place-self-center'></div>
    )}
    {subTitle && (
      <div
        style={{ letterSpacing: 2 }}
        className='text-nero text-sm md:text-xl leading-4 font-normal mt-1 text-center Montserrat font-normal py-2'
      >
        {subTitle}
      </div>
    )}
  </div>
);
SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  underline: PropTypes.bool
};

export default SectionHeading;
