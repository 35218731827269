import { IconMobile, SectionHeading } from '@/components/atomic/atoms';
import { SectionActionButton } from '@/components/atomic/molecules';
import { planEventWithHebaURL, staticMediaStoreBaseURL } from 'config/common';

const BottomHeader = () => (
  <div className='flex flex-row mt-4 Montserrat px-5'>
    <div
      className='self-center leading-5 text-lg'
      style={{ lineHeight: '23px' }}
    >
      <div className='font-extrabold'>
        {`UAE's largest event services team with 17,000+ events enabled`}
      </div>
      <div className='pt-1'>
        {`Whether it's a gala dinner or a wedding ceremony, you need a venue or an
        artist, Hafla will help you get it with no stress..`}
      </div>
    </div>
  </div>
);

const WhyChooseHaflaMobile = ({ haflaServices = [] }) => (
  <div className='mt-5 text-center'>
    <SectionHeading
      title='WHY CHOOSE HAFLA'
      underline
    />
    <BottomHeader />
    <div className='flex flex-row flex-wrap items-start w-full mt-5 mb-5 justify-evenly'>
      {haflaServices.map(({ id, name, image }) => (
        <IconMobile
          key={id}
          {...{
            title: name,
            className: 'img-center h-20 w-20',
            alt: name,
            url: `${staticMediaStoreBaseURL}${image}`
          }}
        />
      ))}
    </div>
    <SectionActionButton
      {...{
        buttonText: 'PLAN YOUR EVENT',
        url: planEventWithHebaURL,
        isOpenInSameTab: false
      }}
    />
  </div>
);

export default WhyChooseHaflaMobile;
